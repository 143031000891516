<template>
  <li
    v-if="checkPermission(item.permission)"
    :id="item.id"
    class="nav-item"
    :class="{
      'active': isActive,
      'disabled': item.disabled
    }"
  >
    <b-link
      v-bind="linkProps"
      class="d-flex align-items-center"
    >
      <div :style="`background-image: url(${isActive ? item.iconActive : item.icon});background-cover: cover;width:24px;height:24px;margin-right:1rem;`" />
      <!-- <b-img class="mr-1" :src="item.icon" :alt="item.title" /> -->
      <!-- <feather-icon :icon="item.icon || 'CircleIcon'" /> -->
      <span
        class="menu-title text-truncate"
        :class="isActive ? 'fw-bold-700' : 'text-dark text-darken-4 fw-bold-700'"
      >{{ item.title  }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
// import { ref } from '@vue/composition-api'
import { BLink, BBadge } from 'bootstrap-vue'
import { checkPermission } from '@/auth/utils'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'

export default {
  components: {
    BLink,
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)

    return {
      isActive,
      linkProps,
      updateIsActive,
      checkPermission,
    }
  },

}
</script>
