import master from './master'

/* eslint-disable global-require */
export default [
  {
    title: 'Toko',
    route: 'toko.index',
    // permission: 'list produk',
    icon: require('@/assets/images/icons/DocumentBlank.svg'),
    iconActive: require('@/assets/images/icons/DocumentBlank-Solid.svg'),
  },
  {
    title: 'Cabang',
    route: 'cabang.index',
    // permission: 'list produk',
    icon: require('@/assets/images/icons/DocumentBlank.svg'),
    iconActive: require('@/assets/images/icons/DocumentBlank-Solid.svg'),
  },
  {
    title: 'Gudang',
    route: 'gudang.index',
    // permission: 'list produk',
    icon: require('@/assets/images/icons/DocumentBlank.svg'),
    iconActive: require('@/assets/images/icons/DocumentBlank-Solid.svg'),
  },
  {
    title: 'Supplier',
    route: 'supplier.index',
    // permission: 'list produk',
    icon: require('@/assets/images/icons/DocumentBlank.svg'),
    iconActive: require('@/assets/images/icons/DocumentBlank-Solid.svg'),
  },
  {
    title: 'Customer',
    route: 'customer.index',
    // permission: 'list produk',
    icon: require('@/assets/images/icons/DocumentBlank.svg'),
    iconActive: require('@/assets/images/icons/DocumentBlank-Solid.svg'),
  },
  {
    title: 'Pengguna',
    route: 'users.index',
    // permission: 'list produk',
    icon: require('@/assets/images/icons/DocumentBlank.svg'),
    iconActive: require('@/assets/images/icons/DocumentBlank-Solid.svg'),
  },
  {
    title: 'Master Produk',
    route: 'master.product.index',
    // permission: 'list produk',
    icon: require('@/assets/images/icons/DocumentBlank.svg'),
    iconActive: require('@/assets/images/icons/DocumentBlank-Solid.svg'),
  },
  {
    title: 'Bank',
    route: 'master-bank.index',
    // permission: 'list produk',
    icon: require('@/assets/images/icons/DocumentBlank.svg'),
    iconActive: require('@/assets/images/icons/DocumentBlank-Solid.svg'),
  },
  {
    title: 'Career',
    route: 'career.index',
    // permission: 'list produk',
    icon: require('@/assets/images/icons/DocumentBlank.svg'),
    iconActive: require('@/assets/images/icons/DocumentBlank-Solid.svg'),
  },
  {
    title: 'Contact Us',
    route: 'contact_us.index',
    // permission: 'list produk',
    icon: require('@/assets/images/icons/DocumentBlank.svg'),
    iconActive: require('@/assets/images/icons/DocumentBlank-Solid.svg'),
  },
  {
    title: 'Admin Users',
    route: 'master-admin.index',
    // permission: 'list user',
    icon: require('@/assets/images/icons/User.svg'),
    iconActive: require('@/assets/images/icons/User-Solid.svg'),
  },
  ...master,
]
