/* eslint-disable global-require */
export default [
  {
    title: 'Master',
    icon: require('@/assets/images/icons/User.svg'),
    iconActive: require('@/assets/images/icons/User-Solid.svg'),
    children: [
      // {
      //   title: 'Produk',
      //   route: 'master-product.index',
      //   // permission: 'show jasa',
      // },
      // {
      //   title: 'Profil Toko',
      //   route: 'master-toko.index',
      //   // permission: 'edit merchant',
      // },
      // {
      //   title: 'Gudang',
      //   route: 'master-gudang.index',
      //   // permission: 'list gudang',
      // },
      // {
      //   title: 'Users',
      //   route: 'master-users.index',
      //   // permission: 'list user',
      // },
      // {
      //   title: 'Cabang',
      //   route: 'master-cabang.index',
      //   // permission: 'list cabang',
      // },
      // {
      //   title: 'Brand',
      //   route: 'master-brand.index',
      //   // permission: 'show jasa',
      // },
      // {
      //   title: 'Barang',
      //   route: 'master-barang.index',
      //   // permission: 'show jasa',
      // },
      // {
      //   title: 'Satuan',
      //   route: 'master-satuan.index',
      //   // permission: 'show jasa',
      // },
    ],
  },
]
